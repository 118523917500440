import * as React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../scss/pages/support.scss"

class SupportPage extends React.Component {
  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll("main p"); 
    regElements.forEach(function(el) {
      el.innerHTML = el.innerHTML.replace(/®/g, "<sup>&reg;</sup>");
    })
  }
  render() {
    // Images
    const simpasLogo = get(this, "props.data.simpasLogo")
    const smartboxLogo = get(this, "props.data.smartboxLogo")

    return (
      <Layout bodyClass="support">
        <Seo title="Support" description="Find support and providers for SIMPAS and SIMPAS-Applied Solutions (SaS)." />

        <section className="padding hero">
          <div className="container--width-md flow">
            <h1>Support</h1>
          </div>
        </section>
        <section className="bg--gray">
          <div className="container container--md flow text-center">
            <p><strong>Learn how SIMPAS-applied Solutions™ Can Save You Money and Increase&nbsp;Yield.</strong></p>
            <div className="text-center">
              <a className="btn" href="/contact-us">
                Connect with a Specialist
              </a>
            </div>
          </div>
        </section>
        <section className="container container--width-md bg--white flow">
          <p className="container--width-sm text-center">
            For installation descriptions, user interface guides, best practices
            and maintenance, read our User&nbsp;Guides.
          </p>
          <div className="row guides">
            <div className="col guide text-center">
              <Img
                fluid={simpasLogo.fluid}
                className="guide__img"
                alt={simpasLogo.description}
              />
              <a
                href="https://simpas.com/userguide"
                target="_blank"
                rel="noopener noreferrer"
                className="btn"
              >
                SIMPAS User Guide
              </a>
            </div>
            <div className="col guide text-center">
              <Img
                fluid={smartboxLogo.fluid}
                className="guide__img"
                alt={smartboxLogo.description}
              />
              <a
                href="https://simpas.com/userguide/smartboxplus"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--green"
              >
                SmartBox+&trade; User Guide
              </a>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
export default SupportPage

export const SupportPageQuery = graphql`
  query SupportPageQuery {
    simpasLogo: contentfulAsset(title: { eq: "simpas-logo-dark" }) {
      id
      description
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
    smartboxLogo: contentfulAsset(title: { eq: "smartbox-logo-png" }) {
      id
      description
      fluid(maxWidth: 500, quality: 100) {
        ...GatsbyContentfulFluid_tracedSVG
      }
    }
  }
`
